import rp1 from 'assets/images/wildlife-sighted-hyderabad/DSC4526.png';
import rp2 from 'assets/images/wildlife-sighted-hyderabad/IMG_0013.png';
import rp3 from 'assets/images/wildlife-sighted-hyderabad/20190501_MON_3308.png';
import rp4 from 'assets/images/wildlife-sighted-hyderabad/IMG_5945.png';
import rp5 from 'assets/images/wildlife-sighted-hyderabad/Nursery.png';
import rp6 from 'assets/images/wildlife-sighted-hyderabad/Rain_forest.png';

export const hyderabadWildlifeImg = [
  {
    id: 1,
    img: rp1,
    alt: '',
  },
  {
    id: 2,
    img: rp2,
    alt: '',
  },
  {
    id: 3,
    img: rp3,
    alt: '',
  },
  {
    id: 4,
    img: rp4,
    alt: '',
  },
  {
    id: 5,
    img: rp5,
    alt: '',
  },
  {
    id: 6,
    img: rp6,
    alt: '',
  },
];

export const timelapseImagery = [
  {
    id: 1,
    img: rp4,
    alt: 'Picture taken on plantation day',
  },
  {
    id: 2,
    img: rp5,
    alt: 'Picture taken 3 months after',
  },
  {
    id: 3,
    img: rp6,
    alt: 'Picture taken 6 months after',
  },
  {
    id: 4,
    img: rp1,
    alt: '',
  },
  {
    id: 5,
    img: rp2,
    alt: '',
  },
  {
    id: 6,
    img: rp3,
    alt: '',
  },
];

export const hyderabadListofSpices = [
  {
    "id": 1,
    "scientific_name": "Syzygium travanicorium",
    "common_name": "Poriyal",
    "icun_assessment": "Critically Endangered",
    "remarks": "Less than 25 individual trees on Earth"
  },
  {
    "id": 2,
    "scientific_name": "Ailanthes triphysa",
    "common_name": "Endemic",
    "icun_assessment": "Endemic",
    "remarks": "Matchwood & plywood"
  },
  {
    "id": 3,
    "scientific_name": "Saraca asoka",
    "common_name": "Sita Ashoka",
    "icun_assessment": "Vulnerable",
    "remarks": "Medicinal"
  },
  {
    "id": 4,
    "scientific_name": "Cinnamomum malabatrum",
    "common_name": "Endemic",
    "icun_assessment": "Endemic and Vulnerable",
    "remarks": "A source of spice and medicine"
  },
  {
    "id": 5,
    "scientific_name": "Diospyros malabarica ",
    "common_name": "Indian persimmon",
    "icun_assessment": "Endemic",
    "remarks": "Treatment of blood diseases, Gonorrhea and leprosy"
  },
  {
    "id": 6,
    "scientific_name": "Michaelia champaca",
    "common_name": "Champaka",
    "icun_assessment": "Near Threatened",
    "remarks": "Sacred Tree; Source of perfume"
  },
  {
    "id": 7,
    "scientific_name": "Artocarpus hirsutus",
    "common_name": "Aini",
    "icun_assessment": "Endemic and vulnerable",
    "remarks": "Source of valuable timber and medicine"
  },
  {
    "id": 8,
    "scientific_name": "Oroxylum indicum",
    "common_name": "Somyaka",
    "icun_assessment": "Vulnerable",
    "remarks": "Potent ayurvedic medicine"
  },
  {
    "id": 9,
    "scientific_name": "Madhuca Longifolia",
    "common_name": "Mahua",
    "icun_assessment": "Vulnerable",
    "remarks": "Potent ayurvedic medicine"
  },
  {
    "id": 10,
    "scientific_name": "Terminalia Bellirica",
    "common_name": "Baheda",
    "icun_assessment": "Endemic",
    "remarks": "Treatment of respiratory illnesses"
  },
  {
    "id": 11,
    "scientific_name": "Aegle marmelos",
    "common_name": "Bael tree",
    "icun_assessment": "Near Threatened",
    "remarks": "Pulp used as summer drink"
  },
  {
    "id": 12,
    "scientific_name": "Hydnocarpus pentandrus",
    "common_name": "Suranti ",
    "icun_assessment": "Vulnerable ",
    "remarks": "Medicinal "
  },
  {
    "id": 13,
    "scientific_name": "Terminalia arjuna ",
    "common_name": "Arjun tree",
    "icun_assessment": "Near threatened",
    "remarks": "Treatment of fractures, ulcers, heart diseases, tumors"
  },
  {
    "id": 14,
    "scientific_name": "Garcinia indica",
    "common_name": "Kokum",
    "icun_assessment": "Endemic and Vulnerable",
    "remarks": "Super food"
  },
  {
    "id": 15,
    "scientific_name": "Commiphora guggulu",
    "common_name": "Guggul",
    "icun_assessment": "Endangered",
    "remarks": "Treatment of tumors, cystic swelling, PCOS, and ulcers."
  },
  {
    "id": 16,
    "scientific_name": "Amoora rohituka",
    "common_name": "Pithraj tree",
    "icun_assessment": "Vulnerable",
    "remarks": "Bark used to treat liver diseases and tumors"
  },
  {
    "id": 17,
    "scientific_name": "Canarium strictum",
    "common_name": "Black dhup",
    "icun_assessment": "Vulnerable",
    "remarks": "Treatment of rheumatism, fever, cough, asthma, epilepsy"
  },
  {
    "id": 18,
    "scientific_name": "Dalbergia latifolia",
    "common_name": "Indian Rosewood",
    "icun_assessment": "Vulnerable",
    "remarks": "Timber variety"
  },
  {
    "id": 19,
    "scientific_name": "simarouba glauca",
    "common_name": "Bitterwood",
    "icun_assessment": "Endemic",
    "remarks": "Manufacture of biofuel, soaps, detergents, lubricants, varnishes"
  },
  {
    "id": 20,
    "scientific_name": "Terminalia Catappa",
    "common_name": "Tavola",
    "icun_assessment": "Endemic",
    "remarks": "To treat scabies, leprosy wounds and other skin diseases "
  }
];
